import React from 'react';
import { ModalWrap } from '@engbers/components/shopmacher-ui/modal-wrap';
import styles from './layout.module.scss';
import { RecoilRoot } from 'recoil';
import { NotificationWrap } from '@engbers/components/shopmacher-ui/notification-wrap';

interface ILayout {
  children: React.ReactNode;
}

// The layout only makes sure
// that the whole content can't run under the header / footer
export const Layout: React.FC<ILayout> = ({ children }) => {
  return (
    <RecoilRoot>
      <div className={styles.layout}>
        <div>{children}</div>
        <ModalWrap />
        <NotificationWrap />
      </div>
    </RecoilRoot>
  );
};
