import React, { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { createClient, LocaleStorage, ResponseError, setProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { FrontasticRenderer } from '@frontastic-engbers/lib/lib/renderer';
import { tastics } from '../frontastic/tastics';
import { Log } from '@frontastic-engbers/helpers/errorLogger';
import styles from './slug.module.css';
import { Layout } from '../components/engbers-specific/layout';
import { useRouter } from 'next/router';
import { IntroLoader } from '../components/engbers-specific/intro-loader';
import { motion } from 'framer-motion';
import { CleverPush } from '@engbers/components/cleverpush';
import { Usercentrics } from '@engbers/components/usercentrics';
import { isStandaloneMode } from '@frontastic-engbers/helpers/utils/standaloneMode';
import { setLastAppActivity } from '@frontastic-engbers/lib/actions/account';
import TagManager from 'react-gtm-module';
import { getTrackingConfig } from '@frontastic-engbers/lib/actions/config';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

const backdropVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale;

  const router = useRouter();
  const { loggedIn, loaded, account } = useAccount();
  const { formatMessage } = useFormat({ name: 'common' });

  useEffect(() => {
    const getConfig = async () => {
      const config = await getTrackingConfig();

      TagManager.initialize({
        gtmId: config.gtmContainerId,
        auth: config.gtmEnvironmentAuth,
        preview: `env-${config.gtmEnvironmentId}`,
      });
    };

    getConfig();
  }, []);

  useEffect(() => {
    const [path] = router.asPath.split('?');
    const publicPaths = ['/login', '/register', '/passwort-vergessen', '/reset-password', '/get-started'];
    if (!publicPaths.includes(path) && loaded && !loggedIn) {
      router.push('/login');
    }
  }, [loaded, loggedIn, router.query.path, router]);

  useEffect(() => {
    if (!loggedIn || !loaded) {
      return;
    }

    if (!localStorage.getItem('lastAppActivity') && isStandaloneMode()) {
      localStorage.setItem('lastAppActivity', new Date().toString());
      setLastAppActivity();
    } else {
      const alreadyTrackedDate = new Date(localStorage.getItem('lastAppActivity'));
      const date = new Date();
      const isSameDay =
        alreadyTrackedDate.getDate() === date.getDate() &&
        alreadyTrackedDate.getMonth() === date.getMonth() &&
        alreadyTrackedDate.getFullYear() === date.getFullYear();

      if (!isSameDay && isStandaloneMode()) {
        localStorage.setItem('lastAppActivity', date.toString());
        setLastAppActivity();
      }
    }
  }, [loggedIn, loaded]);

  setProjectInfo({
    name: 'engbers_Pwa',
    projectId: 'Pwa',
  });

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      {!loaded ? (
        <motion.div
          key={`loading-intro`}
          variants={backdropVariants}
          initial={'initial'}
          animate={'enter'}
          exit={'exit'}
        >
          <IntroLoader />
        </motion.div>
      ) : (
        <motion.div key={`regular-page`} initial={'initial'} animate={'enter'} exit={'exit'}>
          <Head>
            <meta
              name="description"
              content={formatMessage({
                id: 'meta.desc',
                defaultMessage: 'Find largest shopping collections here!',
              })}
            />
            <title>engbersCard</title>
          </Head>
          <Layout>
            <Usercentrics />
            {/*
              // Do NOT use the config but use the fixed channel ID for the PWA
              // We also need to set this channel ID in the service worker statically (engbers/frontend/public/cleverpush-worker.js)
              // Production: BahN3gxwHggwMKdKp
              // Test: pKTfKzEXY4fJCh8QJ
              */}
            {loggedIn && <CleverPush forceChannelId={'BahN3gxwHggwMKdKp'} />}
            <FrontasticRenderer
              wrapperClassName={styles.wrapper}
              mainClassName={styles.main}
              gridClassName={styles.grid}
              data={data}
              tastics={tastics}
            />
          </Layout>
        </motion.div>
      )}
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};
