// import Image from 'next/image';
import LogoImage from './logo.jpg';
import styles from './intro-loader.module.scss';

export const IntroLoader = () => {
  return (
    <div className={styles.wrap}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img alt={'Loading Indicator'} width={80} src={LogoImage.src} />
    </div>
  );
};
